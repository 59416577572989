import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className="services">
      <h1>Our Services</h1>
      <p>At A&D Designs, we offer a wide range of services to meet all your web development needs.</p>
      <div className="service">
        <h2>Custom Website Development</h2>
        <p>Our team of expert developers creates websites that are customized to your specific requirements. We use the latest technologies and best practices to ensure your site is fast, secure, and scalable.</p>
      </div>
      <div className="service">
        <h2>SEO Optimization</h2>
        <p>Our SEO services help your website rank higher on search engines, driving more organic traffic to your site. We use proven strategies and techniques to improve your online visibility and attract more visitors.</p>
      </div>
      <div className="service">
        <h2>Content Management Systems</h2>
        <p>We develop and customize content management systems (CMS) that make it easy for you to manage your website content. Whether you need a simple blog or a complex CMS, we have the expertise to deliver.</p>
      </div>
    </div>
  );
};

export default Services;
