import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo_zoom.webp';
import logo_continued from '../assets/images/logo_zoom_continued.webp';
import '../pages/Home.css';

const GifSwitcher = () => {
  const [currentGif, setCurrentGif] = useState('gif1'); // Initial GIF
  const firstGifDuration = 3100; // Duration of the first GIF in milliseconds
  const secondGifDuration = 700; // Duration of the second GIF in milliseconds

  useEffect(() => {
    let timeout;
    let interval;

    const handleFirstGifLoad = () => {
      timeout = setTimeout(() => {
        setCurrentGif('gif2');
      }, firstGifDuration);
    };

    const handleSecondGifLoad = () => {
      interval = setInterval(() => {
        setCurrentGif('gif2'); // To trigger re-render for second GIF (if needed)
      }, secondGifDuration);
    };

    if (currentGif === 'gif1') {
      const firstGifElement = document.getElementById('first-gif');
      if (firstGifElement.complete) {
        handleFirstGifLoad();
      } else {
        firstGifElement.addEventListener('load', handleFirstGifLoad);
      }
    }

    if (currentGif === 'gif2') {
      const secondGifElement = document.getElementById('second-gif');
      if (secondGifElement.complete) {
        handleSecondGifLoad();
      } else {
        secondGifElement.addEventListener('load', handleSecondGifLoad);
      }
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
      const firstGifElement = document.getElementById('first-gif');
      if (firstGifElement) {
        firstGifElement.removeEventListener('load', handleFirstGifLoad);
      }
      const secondGifElement = document.getElementById('second-gif');
      if (secondGifElement) {
        secondGifElement.removeEventListener('load', handleSecondGifLoad);
      }
    };
  }, [currentGif, firstGifDuration, secondGifDuration]);

  return (
    <div>
      {currentGif === 'gif1' && (
        <img
          id="first-gif"
          src={logo}
          alt="First GIF"
          className='home-banner fade-in'
        />
      )}
      {currentGif === 'gif2' && (
        <img
          id="second-gif"
          src={logo_continued}
          alt="Second GIF"
          className='home-banner fade-in'
        />
      )}
    </div>
  );
};

export default GifSwitcher;