import React from 'react';
import './Home.css';
// import project1 from '../assets/images/project1.jpg';
// import project2 from '../assets/images/project2.jpg';
// import project3 from '../assets/images/project3.jpg';
// import logo from '../assets/images/logo.gif';
import GifSwitcher from '../components/GifSwitcher';

const Home = () => {
  return (
      <div className="home">
        {/* <img src={logo} alt="Home Banner" className="home-banner" /> */}
        <GifSwitcher alt="Home Banner" className="home-banner" />
        <p>Your vision, our creation. We build custom websites tailored to your needs.</p>
        <section className="home-services">
          <div className="service">
            {/* <img src={project1} alt="Project 1" /> */}
            <h3>Custom Website Development</h3>
            <p>We create fully customized websites that are tailored to your business needs and goals. Whether you need a simple landing page or a complex web application, we've got you covered.</p>
          </div>
          <div className="service">
            {/* <img src={project2} alt="Project 2" /> */}
            <h3>Responsive Design</h3>
            <p>Our websites are designed to look great on any device, from desktops to smartphones. We ensure your site is fully responsive and provides an excellent user experience.</p>
          </div>
          <div className="service">
            {/* <img src={project3} alt="Project 3" /> */}
            <h3>SEO Optimization</h3>
            <p>Our SEO experts optimize your website to rank higher on search engines, driving more traffic to your site and increasing your online visibility.</p>
          </div>
        </section>
      </div>
  );
};

export default Home;
