import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  if (isLoading) {
    // Optionally show a loading spinner or message while loading
    return <div>Loading...</div>;
  } else {
    return (isAuthenticated && user["https://axddesigns.com/oauth/roles"].includes("Admin")) ? children : <Navigate to="/" />;
  }
};

export default PrivateRoute;
