import React, { useEffect, useState } from 'react'
import { createClient } from '@supabase/supabase-js'
import './Inbox.css'

const supabaseUrl = 'https://wjaukeoevmihjjozqked.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndqYXVrZW9ldm1paGpqb3pxa2VkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQ2NTk5MzEsImV4cCI6MjAxMDIzNTkzMX0.vsYeuLJv5i0DYSixvnQhF5_ec6uqH8mqYOoKmXaJIBQ'
const supabase = createClient(supabaseUrl, supabaseKey)

const Inbox = () => {
  const [entries, setEntries] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchEntries = async () => {
      const { data, error } = await supabase
        .from('axddesigns-requests')
        .select('*')

      if (error) {
        console.error('Error fetching entries:', error)
      } else {
        setEntries(data)
      }
      setLoading(false)
    }

    fetchEntries()
  }, [])

  if (loading) {
    return <div className='loading-text'>Loading...</div>
  }

  return (
    <div className='inbox-header'>
      <h1>Contact Inbox</h1>
      {entries.length === 0 ? (
        <p>No entries found.</p>
      ) : (
        <ul>
          {entries.map((entry) => (
            <li key={entry.id}>
              <strong>Created At:</strong> {entry.created_at} <br />
              <strong>Name:</strong> {entry.name} <br />
              <strong>Email:</strong> {entry.email} <br />
              <strong>Message:</strong> {entry.message}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Inbox
