import React from 'react';
import './Portfolio.css';
// import project1 from '../assets/images/project1.jpg';
// import project2 from '../assets/images/project2.jpg';
// import project3 from '../assets/images/project3.jpg';

const Portfolio = () => {
  return (
    <div className="portfolio">
      <h1>Our Portfolio</h1>
      <p>Take a look at some of the projects we've completed for our clients.</p>
      <div className="portfolio-items">
        <div className="portfolio-item">
          {/* <img src={project1} alt="Project 1" /> */}
          <h2>Project 1: eCommerce Website</h2>
          <p>We developed a fully functional eCommerce website for a retail company, featuring a user-friendly interface, secure payment processing, and seamless shopping experience.</p>
        </div>
        <div className="portfolio-item">
          {/* <img src={project2} alt="Project 2" /> */}
          <h2>Project 2: Corporate Website</h2>
          <p>We created a professional corporate website for a financial services firm, highlighting their services, team, and expertise with a clean and modern design.</p>
        </div>
        <div className="portfolio-item">
          {/* <img src={project3} alt="Project 3" /> */}
          <h2>Project 3: Educational Platform</h2>
          <p>We built an educational platform for an online learning company, featuring interactive courses, user profiles, and progress tracking.</p>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
