import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>A&D Designs is a premier web development company dedicated to delivering high-quality web solutions. With years of experience in the industry, we have the skills and expertise to bring your vision to life.</p>
      <div className="about-section">
        <h2>Our Mission</h2>
        <p>Our mission is to provide top-notch web development services that help businesses succeed online. We are committed to excellence and customer satisfaction, and we strive to exceed our clients' expectations with every project we undertake.</p>
      </div>
      <div className="about-section">
        <h2>Our Team</h2>
        <p>We are a team of experienced web developers, designers, and digital strategists who are passionate about what we do. Our diverse skill set and collaborative approach enable us to deliver innovative solutions that meet our clients' unique needs.</p>
      </div>
    </div>
  );
};

export default About;
