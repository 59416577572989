import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <div className='conditions-header'>
        <h1>Terms and Conditions</h1>
        <p>These terms and conditions outline the rules and regulations for the use of A&D Designs' website and services.</p>
      </div>
      <h2>Acceptance of Terms</h2>
      <p>By accessing and using our website and services, you accept and agree to be bound by these terms and conditions.</p>
      <h2>Intellectual Property</h2>
      <p>All content on our website, including text, graphics, logos, and images, is the property of A&D Designs and is protected by intellectual property laws.</p>
      <h2>Use of Services</h2>
      <p>You agree to use our services only for lawful purposes and in accordance with these terms and conditions.</p>
      <h2>Limitation of Liability</h2>
      <p>We are not liable for any damages arising from the use or inability to use our website or services.</p>
      <h2>Changes to Terms</h2>
      <p>We may update these terms and conditions from time to time. We will notify you of any changes by posting the new terms and conditions on our website.</p>
    </div>
  );
};

export default TermsAndConditions;
