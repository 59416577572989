import React, { useState } from 'react';
import './Contact.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { createClient } from '@supabase/supabase-js'




const Contact = () => {
  const supabaseUrl = 'https://wjaukeoevmihjjozqked.supabase.co'
  const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndqYXVrZW9ldm1paGpqb3pxa2VkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQ2NTk5MzEsImV4cCI6MjAxMDIzNTkzMX0.vsYeuLJv5i0DYSixvnQhF5_ec6uqH8mqYOoKmXaJIBQ'
  const supabase = createClient(supabaseUrl, supabaseKey)

  const { isAuthenticated, user } = useAuth0();

  console.log(user);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    
    // Here you can add the logic to send the form data to your backend or an email service

    if(isAuthenticated){
      formData.name = user.name;
      formData.email = user.email;
    }
    axios.post('https://us-central1-logging-server-1c685.cloudfunctions.net/app/google-oauth2|110583152279113035685', {
      log: formData
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.error('Error:', error);
    });

    async function postEntry(name, email, message) {
      const { data, error } = await supabase
        .from('axddesigns-requests')
        .insert([
          { name, email, message }
        ])
    
      if (error) {
        console.error('Error posting entry:', error)
      } else {
        console.log('Entry posted:', data)
      }
    }
    
    // Call the function with example data
    postEntry(formData.name, formData.email, formData.message)    

    // end logic
    
    alert('Message sent!');
  };

  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>If you have any questions or would like to discuss a project, please feel free to get in touch with us. We'd love to hear from you!</p>
      <form onSubmit={handleSubmit}>
      {isAuthenticated ? (
        <div className="form-group">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={user.name} onChange={handleChange} required disabled/>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={user.email} onChange={handleChange} required disabled/>
          </div>
        </div>): (
        <div className="form-group">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
        </div>)}
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default Contact;
