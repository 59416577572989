import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className='policy-header'>
        <h1>Privacy Policy</h1>
        <p>This is the privacy policy of A&D Designs. We value your privacy and are committed to protecting your personal information.</p>
      </div>
      <h2>Information Collection</h2>
      <p>We collect personal information when you use our services, such as your name, email address, and payment information.</p>
      <h2>Use of Information</h2>
      <p>We use your personal information to provide and improve our services, communicate with you, and process transactions.</p>
      <h2>Sharing of Information</h2>
      <p>We do not share your personal information with third parties, except as necessary to provide our services or comply with the law.</p>
      <h2>Security</h2>
      <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.</p>
      <h2>Changes to Privacy Policy</h2>
      <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on our website.</p>
    </div>
  );
};

export default PrivacyPolicy;
